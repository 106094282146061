import React from "react";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";
import Pagination from "../utilities/pagination";

const Posts = ({
  posts,
  totalPages,
  currentPage,
  baseUri,
  pageContext,
  location,
}) => {
  return (
    <>
      <div className="posts">
        {posts.edges && posts.edges.length ? (
          posts.edges.map(({ node: post }, i) => (
            <div key={post.id} className="my-4">
              <div className="row">
                {post.featuredImage && (
                  <div className="post col-md-5 mb-md-0 mb-3">
                    <Link to={post.uri}>
                      <CmsImage
                        className="image-fluid"
                        image={post.featuredImage.node}
                      />
                    </Link>
                  </div>
                )}
                <div className="post col-md-7">
                  <h3 className="post-title">
                    <Link to={post.uri}>{post.title}</Link>
                  </h3>
                  <Link to={post.uri} className="read-more">
                    Read More{" "}
                    <svg
                      className="ms-3"
                      width="18"
                      height="9"
                      viewBox="0 0 18 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.0782 4.07357C17.2781 4.26875 17.2859 4.58544 17.0955 4.78091L13.9934 7.96632C13.8031 8.1618 13.4867 8.16203 13.2868 7.96685C13.0869 7.77166 13.0791 7.45497 13.2695 7.2595L15.5394 4.92856L0.377547 4.92856L0.377256 3.92822L15.515 3.92822L13.131 1.60073C12.9311 1.40555 12.9234 1.08886 13.1137 0.893384C13.3041 0.697912 13.6205 0.697678 13.8204 0.892862L17.0782 4.07357Z"
                        fill="#00A950"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-posts">
            <h3>No posts found.</h3>
          </div>
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
          endId="blog"
        />
      )}
    </>
  );
};

export default Posts;
