import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import { Helmet } from "react-helmet";
import Tagline from "../components/sections/tagline";

const Blog = ({
  data: {
    allWpPost: posts,
    wpPage: page,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
  pageContext,
  location,
}) => {
  const banner = {
    name: "blog-banner",
    image: page.blog.background,
    altText: page.blog.background.altText,
    pageContext: pageContext,
    location: location,
  };
  const tagline = {
    name: "blog-tagline",
    text: page.blog.taglineText
      ? page.blog.taglineText
      : "BUILDING TO A HIGHER STANDARD",
  };

  const blogContent = page.blog.content;

  const blogTitle = page.blog.content ? "" : page.title;

  return (
    <>
      <Helmet>
        <title>
          {page.metaTitle ? page.metaTitle : page.title} -
          {themeSettings.siteMetaTitle}
        </title>
        {page.metaDescription && (
          <meta name="description" content={page.metaDescription} />
        )}
        {page.pageCssOverrides && <style>{page.pageCssOverrides}</style>}
        {themeSettings.customCssOverrides && (
          <style>{themeSettings.customCssOverrides}</style>
        )}
      </Helmet>
      <BlogElem
        blogContent={blogContent}
        banner={banner}
        pageContext={pageContext}
        location={location}
        blogTitle={blogTitle}
      >
        <Posts
          posts={posts}
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      </BlogElem>
      <Tagline data={tagline}></Tagline>
    </>
  );
};

export const pageQuery = graphql`
  query Blog($id: String!, $perPage: Int!, $skipItems: Int!) {
    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
    ) {
      edges {
        node {
          id
          title
          uri
          date(fromNow: true, formatString: "MMM DD YYYY")
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          excerpt
        }
      }
    }
    wpPage(id: { eq: $id }) {
      blog {
        content
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
        taglineText
      }
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Blog;
