import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Content from "../components/utilities/content";
import { Helmet } from "react-helmet";
import Tagline from "../components/sections/tagline";

const Post = ({
  data: {
    wpPost: post,
    wpPage: { blog },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext,
  location,
}) => {
  const banner = {
    name: "post-banner",
    image: post.featuredImage?.node,
    altText: post.featuredImage?.node.altText
      ? post.featuredImage.node.altText
      : "",
    pageContext: pageContext,
    location: location,
  };
  const tagline = {
    name: "blog-tagline",
    text: post.postExtra.taglineText
      ? post.postExtra.taglineText
      : "BUILDING TO A HIGHER STANDARD",
  };

  return (
    <>
      <Helmet>
        <title>
          {post.page.metaTitle ? post.page.metaTitle : post.title} -
          {themeSettings.siteMetaTitle}
        </title>
        {post.metaDescription && (
          <meta name="description" content={post.metaDescription} />
        )}
        {post.pageCssOverrides && <style>{post.pageCssOverrides}</style>}
        {themeSettings.customCssOverrides && (
          <style>{themeSettings.customCssOverrides}</style>
        )}
      </Helmet>
      <BlogElem
        title={post.title}
        banner={banner}
        pageContext={pageContext}
        location={location}
      >
        <Content className="content-blog">{post.content}</Content>
      </BlogElem>
      <Tagline data={tagline}></Tagline>
    </>
  );
};

export const pageQuery = graphql`
  query Post($id: String!, $parentId: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
            extension
            publicURL
          }
        }
      }
      postExtra {
        taglineText
      }
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
    }
    wpPage(id: { eq: $parentId }) {
      blog {
        content
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Post;
